<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../../components/TemplateHeaderTable'

export default {
  name: 'bigthanks',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    const { turntableId } = this.$route.query
    if (turntableId) {
      api.command.getList.call(this, {
        url: '/turntableZjRecord/page',
        current: 1,
        paramsValue: {
          turntableId: turntableId,
          prizeType: '2'
        }
      })
    }
  },
  methods: {
    getHeader() {
      return [
        {
          name: '中奖人',
          key: 'nickname',
          type: 'input'
        },
        {
          name: '联系方式',
          key: 'mobilePhone',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-120'
        },
        {
          dataIndex: 'nickname',
          title: '中奖人',
          type: 'lt-150',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.headImg} />
                <span>{text}</span>
              </div>
            )
          }
        },
        {
          dataIndex: 'mobilePhone',
          title: '联系方式',
          type: 'lt-150'
        },
        {
          dataIndex: 'moduleName',
          title: '中奖位置',
          type: 'lt-150'
        },
        {
          dataIndex: 'createDate',
          title: '中奖时间',
          type: 'lt-150',
          sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix()
        }
      ]
    },
    getButton() {
      return []
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
</style>
